.ellipses-content-wrapper {
  max-width: 1200px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  left: 0;
  position: relative;
  z-index: 25;
}

.ellipses-header {
  width: 100%;
  height: 2rem;
  padding: 4.5rem 0;
  display: block;
  font-family: 'Trade Gothic', sans-serif;
}

.ellipses-header-item {
  float: center;
  text-align: center;
}

.ellipses-logo {
  margin-top: 200px;
  margin-left: 127px;
}

.ellipses-heading {
  font-weight: bold;
  line-height: 1.7rem;
  margin: 0 auto;
  text-align: center;
  font-size: 24px;
}

.coords {
  font-size: 1.1rem;
  transform: rotate(-90deg) translateY(50%);
  float: left;
  position: relative;
  top: 40%;
  letter-spacing: 0.2rem;
  left: -11.5rem;
  margin: 0;
}

.info-ellipses {
  margin-top: 242px;
  margin-left: 129px;
  position: absolute;
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 23px;
}

.ellipses-container {
  width: 30rem;
  height: 30rem;
  border-radius: 50%;
  margin: 0 auto;
  position: relative;
  top: 0rem;
}

.ellipses-container .greeting {
  position: absolute;
  top: 11.6rem;
  left: 13rem;
  right: 0;
  margin: 0 auto;
  text-transform: uppercase;
  letter-spacing: 4rem;
  font-size: 2.2rem;
  font-weight: 400;
  opacity: 0.5;
}

.ellipses-container .greeting:after {
  content: '';
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 50%;
  display: inline-block;
  background-color: #454545;
  position: relative;
  top: -0.65rem;
  left: -5.05rem;
}

.ellipses {
  border-radius: 50%;
  position: absolute;
  top: 0;
  border-style: solid;
}

.ellipses-outer--thin {
  width: 100%;
  height: 100%;
  border-width: 1px;
  border-color: #454545;
}

.ellipses-outer--thin--slow {
  animation: ellipsesOrbit 18s ease-in-out infinite;
}

.ellipses-outer--thin--fast {
  animation: ellipsesOrbit 1s ease-in-out infinite;
}

.ellipses-outer--thin:after {
  content: '';
  background-image: url('../../assets/img/ellipses_dial.png');
  background-repeat: no-repeat;
  background-position: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  opacity: 0.15;
}

.ellipses-outer--thick {
  width: 99.5%;
  height: 99.5%;
  border-color: #454545 transparent;
  border-width: 2px;
  transform: rotate(-45deg);
}

.ellipses-outer--thick--slow {
  animation: ellipsesRotate 18s ease-in-out infinite;
}

.ellipses-outer--thick--fast {
  animation: ellipsesRotate 1s ease-in-out infinite;
}

.ellipses-orbit {
  width: 2.5rem;
  height: 2.5rem;
  border-width: 2px;
  border-color: #454545;
  top: 0.5rem;
  right: 6.75rem;
}

.ellipses-orbit:before {
  content: '';
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  display: inline-block;
  background-color: #454545;
  margin: 0 auto;
  left: 0;
  right: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@keyframes ellipsesRotate {
  0% {
    transform: rotate(-45deg);
  }

  100% {
    transform: rotate(-405deg);
  }
}

@keyframes ellipsesOrbit {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.textMessage {
  font-size: medium;
  margin-top: 5%;
  text-align: center;
}

.message-wrapper {
  position: Absolute;
  align-content: center;
  align-items: center;
  width: 100%;
}

.loading {
  overflow: hidden;
}

.loading-text {
  position: absolute;
  margin-left: -194px;
  margin-top: 106px;
  font-size: 25px;
  font-family: 'Trade Gothic';
}
