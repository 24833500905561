.footer {
  margin: 32px 0;
  display: flex;
  align-items: center;
}

.footer-button {
  min-width: 110px !important;
  margin-right: 8px;
}

.reset-button {
  margin-left: auto;
}

.cancel-button {
  margin-right: 0;
}

.ml-auto {
  margin-left: auto;
}
