.form-privilege-list-container {
  border: 1px solid #e8e8e8;
  border-radius: 17px;
  overflow: auto;
  padding: 8px 24px;
  background: white;
}

.form-privilege-list-header {
  right: 0px;
  position: absolute;
  display: flex;
  height: 0px;
}

.form-privilege-size-component {
  margin-top: 15px;
  display: block;
}

.list-users-list-wrapper {
  margin: 0 1% !important;
  padding: 1px 2% !important;
  height: 300px;
}

.list-users-list-wrapper > div {
  margin-bottom: 10px !important;
}

.list-users-list-wrapper
  > div.ReactTable.table
  > div.rt-table
  > div.rt-tbody
  > div
  > div:hover {
  background: var(--er-teal);
  color: white;
  cursor: pointer;
}

.list-users-list-wrapper > div.ReactTable.table > div.rt-table > div.rt-tbody {
  overflow-y: scroll;
  height: 216px;
}

.list-users-input-section-wrapper {
  margin: 1% 1% !important;
  padding: 1px 2% !important;
}

.list-users-input-section-wrapper > div {
  margin-bottom: 10px !important;
}

.list-users-input-row {
  margin-left: 10px;
  display: flex !important;
}

.form-privilege-container {
  display: flex;
  margin-top: 10px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-privilege-item {
  flex: 1 1 100%;
}
.form-privilege-item input {
  box-sizing: border-box;
}

.form-privilege-select {
  flex: 0 1 90%;
}

.form-privilege-button {
  flex: 0 1 6%;
}

.form-privilege-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-privilege-buttons {
  margin-left: 10px;
}

.submit-container {
  margin-top: 10px;
}
