.content-section-row {
  display: flex;
  align-items: baseline;
  background-color: #f7f7f7;
  padding: 12px 24px 16px;
  margin: 0 2% 12px;
  border-radius: 16px;
}

.content-section-title-img {
  display: block;
  width: 32px;
  height: auto;
  padding-right: 24px;
  position: relative;
  top: 6px;
}

.content-section-content {
  margin: 0 12px;
  flex: 0 0 38%;
}

.content-section-row-title {
  font-family: 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial,
    sans-serif;
  font-size: 21px;
  text-transform: uppercase;
  font-weight: bold;
}

.content-section-row-description {
  font-size: 12px;
  text-transform: none;
  font: caption;
}

.content-section-col-search {
  margin-left: auto;
}

.content-section-col-search {
  min-width: 300px;
}
.content-section-search-input {
  position: relative;
}

.content-section-search-input input {
  box-sizing: border-box;
  margin: 0;
}

.content-section-search-input::after {
  display: block;
  position: absolute;
  top: 8px;
  right: 17px;
  content: '⚲';
  transform: rotate(-45deg);
  font-size: 20px;
  color: #e5e5e5;
}

.content-section-section {
  margin: 1% 2% !important;
  padding: 0 !important;
  height: 72px !important;
}

.content-section-col-statistic {
  width: 250px !important;
  flex: 0 0 50% !important;
  max-width: 270px !important;
  padding: 0 16px !important;
}

.content-section-section.small {
  margin: 5px 5px !important;
  padding: 0 !important;
  height: 42px !important;
}

.content-section-row.small {
  display: flex;
  padding: 6px !important;
  height: 32px !important;
}

.content-section-col-img.small {
  margin-top: 0px;
  padding-left: 0px !important;
}

.content-section-title-img.small {
  width: 44px !important;
  height: 28px;
  top: 0px;
  padding: 0px;
}

.content-section-col-img.small {
  margin-top: 2px;
  padding-left: 12px !important;
  width: 44px !important;
  max-width: 44px !important;
}

.content-section-content.small {
  margin-left: 0px;
  height: 32px;
  top: 1px;
  /* font-size: 4px; */
}

.content-section-row-title.small {
  font-size: 7px !important;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 4px;
}

.content-section-row-description.small {
  display: none !important;
}

.title-color-full {
  color: var(--er-accent);
}

.title-color-full.link:hover {
  color: var(--er-teal);
}

.mini-card {
  margin-top: 0px !important;
  margin-left: 0px !important;
  padding: 10px !important;
  min-width: 48px !important;
  width: 48px !important;
}

.mini-card-lg {
  margin-top: 0px !important;
  margin-left: 0px !important;
  padding: 10px !important;
  min-width: 70px !important;
  width: 70px !important;
}

.mini-card-wrapper {
  padding-right: 3px !important;
  padding-left: 8px !important;
  flex-grow: 0 !important;
}

.mini-card > h1,
.mini-card-lg > h1 {
  font-size: 24px;
  font-weight: 24px;
  line-height: 1em;
  letter-spacing: -0.5px;
  color: var(--er-teal);
  text-transform: uppercase;
  margin: 0;
  margin-top: -2px;
  padding: 0;
  text-align: center;
}

.mini-card > h3,
.mini-card-lg > h3 {
  font-size: 12px;
  font-weight: bold;
  color: var(--er-grey-7);
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  text-align: center;
  margin-top: 1px;
}

.content-section-toolbar-button {
  padding: 0px;
  width: 32px !important;
  height: 32px !important;
  min-width: 32px !important;
  min-height: 32px !important;
  border: none;
  cursor: pointer;
  background: transparent;
}

.content-section-toolbar-button > img {
  width: 32px !important;
  height: 32px !important;
}

.right-aside-wrapper {
  display: flex;
  align-self: center;
  margin-left: auto;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.right-aside-wrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-left: auto;
}

.right-aside-wrapper button {
  margin-right: 18px;
  height: 100% !important;
  margin-bottom: 2px;
  cursor: pointer;
}

.left-aside-mini-cards {
  position: relative;
  display: flex;
  margin-left: -15px;
  width: 100%;
}

.left-aside-search-mini-cards {
  position: relative;
  display: flex;
  margin-left: -7px;
  width: 100%;
}

.content-section-link {
  flex: 0 0 48%;
  max-width: 50%;
  text-decoration: none !important;
  color: black !important;
}

.content-section-row-small {
  display: flex;
  align-items: center;
  background-color: #f7f7f7;
  padding: 12px 16px;
  margin: 0 12px 6px;
  border-radius: 16px;
  position: relative;
}

.content-section-title-img-small {
  display: block;
  width: 32px;
  height: auto;
  padding-right: 24px;
  position: relative;
  top: 2px;
}

.content-section-row-title-grey {
  font-family: 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial,
    sans-serif;
  font-size: 18px;
  color: var(--er-grey-7);
  text-transform: uppercase;
  font-weight: bold;
  white-space: nowrap;
  text-align: center;
}

.content-section-row-title-grey-bigfont {
  font-family: 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial,
    sans-serif;
  font-size: 20px;
  color: var(--er-grey-7);
  text-transform: uppercase;
  font-weight: bold;
  white-space: nowrap;
  text-align: center;
}
