.editable-table-input-wrapper {
  display: flex;
  width: 100%;
  height: 27px;
}

.editable-table-input-inner {
  text-align: left;
  width: 100% !important;
  height: 27px;
  min-width: 100%;
  font: 500 12px/22px 'Helvetica Neue Regular', sans-serif !important;
}

.editable-table-helper-button {
  right: 25px;
  position: relative;
  width: 24px;
  min-width: 24px;
  cursor: pointer;
  background: var(--er-grey-1);
  height: 25px;
  margin-top: 1px;
  border: none !important;
}

.editable-table-helper-button:hover {
  background: var(--er-teal);
  color: white;
}

.editable-table-helper-button-img {
  width: 10px;
  align-items: center;
  padding: 0px;
  position: absolute;
  left: 5px;
  top: 9px;
}
