@import './normalize.css';
@import './card.css';
@import './statistic.css';
@import './select.css';
@import './containers.css';
@import './steps.css';
@import './result.css';
@import './tag.css';
@import './badge.css';
@import './toast.css';
@import './popover.css';
@import './avatar.css';
@import './timeline.css';
@import './list.css';
@import './modal.css';
@import './bootstrap.css';
@import './epic-icon-custom.css';
@import './button.css';

textarea,
input[type] {
  box-sizing: border-box;
}

/* Fixing the font of the radio buttons from the guide epic ui guide */
label {
  font-family: 'Helvetica Neue Regular', Helvetica, Arial, sans-serif !important;
}

:root {
  --main-bg-color: brown;
  /* ***********Colors********** */
  --er-blue: #5197d6;
  --er-accent: #fa5400;
  --er-success: #48bd1f;
  --er-error: #fe0000;
  --er-warn: rgb(238, 180, 80);
  --er-teal: #007a87;
  /* ***********Hover State Colors********** */
  --er-hover-blue: #7cb1e0;
  /* *******grey-scale******* */
  --er-white: #ffffff;
  --er-black: #111;
  --er-grey-1: #f7f7f7;
  --er-grey-2: #ececec;
  --er-grey-3: #e5e5e5;
  --er-grey-4: #c7c7c7;
  --er-grey-5: #b2b2b4;
  --er-grey-6: #8b8c8c;
  --er-grey-7: #6d6d6d;
  /* *******z-index********* */
  --z-index-layer-1: 1;
  --z-index-layer-2: 2;
  --z-index-layer-3: 3;
  --z-index-layer-4: 4;
  --z-index-layer-5: 5;
  --z-index-layer-6: 6;
  --z-index-layer-7: 7;
  --z-index-layer-8: 8;
  --z-index-layer-9: 9;
  --z-index-layer-10: 10;
  --highest-order-z-index: 2000;
  --max-z-index: 20001;
}

body {
  margin: 0;
}

@font-face {
  font-family: 'Futura';
  src: url(../fonts/futura/FuturaNDforNike365Cn-XBd.woff2) format('woff2'),
    url(../fonts/futura/FuturaNDforNike365Cn-XBd.woff) format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Trade Gothic';
  src: url(../fonts/trade-gothic/TradeGothicforNike365-BdCn.woff2)
      format('woff2'),
    url(../fonts/trade-gothic/TradeGothicforNike365-BdCn.woff) format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue Regular';
  src: url(../fonts/helvetica-neue/HelveticaNeueRegular.woff2) format('woff2'),
    url(../fonts/helvetica-neue/HelveticaNeueRegular.woff) format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue Medium';
  src: url(../fonts/helvetica-neue/HelveticaNeueMedium.woff2) format('woff2'),
    url(../fonts/helvetica-neue/HelveticaNeueMedium.woff) format('woff');
  font-weight: 500;
  font-style: normal;
}

.file-input-container .file-input input[type='text'] {
  width: 100% !important;
}
