.toopbar-title-no-navigable {
  font-size: 30px;
  text-align: center;
  width: 100%;
  position: relative;
  background: var(--er-accent);
  color: white;
  z-index: 0;
}

nav {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.navigation-link {
  padding: 14px 25px 14px 25px;
}

.navigation-link:hover {
  background-color: #fff;
}

.navigation-icon {
  padding-right: 5px;
  vertical-align: baseline;
}

.topbar {
  margin-bottom: 12px;
}
