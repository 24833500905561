.formToolBar {
    right: 4px;
    display: flex;
    position: absolute;
    padding-top: 6px;
}

.formToolBar>a {
    color: black !important;
}

.toolbarButton {
	padding: 0px 10px 0px 10px !important;
	color: black !important;
}
