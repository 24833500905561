.card-container-table-group {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  height: calc(100vh - 174px);
  overflow-y: auto;
  overflow-x: hidden !important;
  align-content: flex-start;
}

.container-table-group-layout-body {
  margin: 0% 12px !important;
  padding: 0 !important;
  border-top: 3px solid var(--er-accent) !important;
}

.container-table-group-layout-body [class*='header'] {
  margin-bottom: 0px !important;
}

.card-table-group {
  margin: 12px;
  display: flex;
  flex-direction: column;
}

.card-table-group > div {
  display: none;
}

.card-table-group > p {
  word-break: break-word;
}

.table-groups-counter {
  font-family: 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial,
    sans-serif;
  /* font-family: 'Helvetica Neue Medium', Helvetica, Arial, sans-serif; */
  font-size: 24px;
  font-weight: bold;
  line-height: 1em;
  letter-spacing: -0.5px;
  color: var(--er-teal);
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  text-align: center;
}

.table-groups-tiles {
  display: flex;
}

.table-groups-tiles-div {
  margin: 0px 18px;
  text-align: center;
}

.table-groups-counter-new {
  font-family: 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial,
    sans-serif;
  /* font-family: 'Helvetica Neue Medium', Helvetica, Arial, sans-serif; */
  font-size: 24px;
  font-weight: bold;
  line-height: 1em;
  letter-spacing: -0.5px;
  color: var(--er-teal);
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100px;
}
