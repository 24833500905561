.datepicker-helper-modal {
    width: 300px !important;
    min-width: 300px;
    padding: 35px 15px 15px 5px !important;
}

.datepicker-helper-modal-form-section {
    border-radius: 1em !important;
    padding: 1px 24px 24px 24px !important;
    margin: 11px 11px !important;
    width: 241px !important;
}

.simple-modal-children-wrapper {
    margin-top: 15px;
}