.fontBrand {
  font-family: 'Trade Gothic', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.fontMarketing {
  font-family: 'FuturaExtraBold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.fontBase,
.overviewTable {
  font: 500 12px/22px 'Helvetica Neue Regular', sans-serif;
}

.overviewTable {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  border-radius: 15px;
  box-sizing: content-box !important;
}

.rt-tr-group {
  border-radius: 15px 0px 0px 0px;
  border: 0px !important;
  max-height: 27px !important;
}

.rt-td {
  border: 0px !important;
}

.rt-resizable-header-content {
  width: 100% !important;
  padding: 5px !important;
  height: 100% !important;
}

[role='gridcell'] {
  padding: 0 !important;
  border: 0px !important;
}

[role='columnheader'] {
  padding: 0 !important;
}

.buttonSearch {
  position: absolute;
  right: 4;
  top: 12;
  padding: 5px 2px 0px 2px !important;
}

.buttonClear {
  position: absolute;
  right: 30;
  top: 12;
  padding: 5px 2px 0px 2px !important;
}

.buttonUpload {
  display: flex !important;
  color: black !important;
}

.inputFilter {
  width: 100% !important;
  border: 0px !important;
}

.filterRow {
  margin-right: 0 !important;
  margin-left: 0 !important;
  padding: 0px !important;
  border: 0px !important;
  height: 100% !important;
  flex-wrap: nowrap !important;
}

.filterRowInput {
  padding: 0px !important;
  background: #fff !important;
  border: 0px !important;
  -webkit-appearance: none;
  margin: 0;
}

.divHeaderTableLabel {
  text-align: left;
  padding: 0px 12px 0px 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
}

.headerTableLabel {
  margin-bottom: 0px !important;
  margin-right: 10px !important;
  font: 700 16px/24px 'Trade Gothic' !important;
  cursor: pointer !important;
}

.headerTableIcon {
  text-align: left;
}

.Table__itemCount {
  font-size: 14px;
}

.Table__pagination {
  display: flex;
  justify-content: flex-end;
  padding: 10px 10px 10px;
}

.Table__pageButton {
  font-size: 15px;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.Table__pageButton:disabled {
  cursor: not-allowed;
  color: gray;
}

.Table__pageButton--active {
  color: #45b3e3;
  font-weight: bold;
}

.react-datepicker,
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container > input,
.react-datepicker-ignore-onclickoutside {
  width: 100% !important;
  height: 100% !important;
}

.tableToolBar {
  right: 4px;
  display: flex;
  position: absolute;
  padding-top: 6px;
}

.inputError {
  border: 1px solid red !important;
  background-color: rgb(255, 255, 255);
  padding: 0 8px;
  box-sizing: border-box;
}

.rt-table {
  padding: 10px;
}

.cell-input {
  background-color: rgb(255, 255, 255);
  border: 1px solid #f5f5f5;
  padding: 0 8px;
  box-sizing: border-box;
}

input:disabled {
  background-color: rgb(236, 236, 236);
}

input[type='number'] {
  padding: 0 0 0 8px;
}

input.inner-input-list[type='number'] {
  padding: 0 15px 0 8px !important;
}

.overview-table-download-form-section {
  text-align: left !important;
}

.filter-setting-input {
  width: 100%;
  height: 100%;
  padding: 0 0 0 8px !important;
}

.filter-setting-section-wrapper {
  margin: 1% 3% !important;
  padding: 1px 2% !important;
  height: 345px;
}

.filter-setting-section-wrapper [class*='ReactTable table'] {
  height: 263px !important;
}

.filter-setting-actions-container {
  display: flex;
}

.filter-setting-section-wrapper [class*='header_'] {
  margin-bottom: 25px;
}

.filter-setting-section-wrapper
  > div.ReactTable.table
  > div.rt-table
  > div.rt-tbody {
  overflow-y: scroll;
  height: 216px;
}

.filter-setting-del-item {
  height: 100% !important;
}

.filter-setting-hidden {
  visibility: hidden;
}
