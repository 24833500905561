.card-footer {
  display: flex !important;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  color: var(--er-grey-7);
  text-transform: uppercase;
  padding: 0;
  bottom: 0px;
  margin-bottom: 12px;
  position: absolute;
}

.card-footer-right-extreme-left {
  left: -43px;
}

.card-footer-right-column {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
