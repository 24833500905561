.card-container-priv-table-group {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  padding-bottom: 30px;
  margin: 12px;
  align-content: flex-start;
}

.card-container-priv-table-group-custom-title-row {
  display: flex;
  align-items: center;
  top: -14px;
  position: relative;
  text-transform: none;
}

.card-container-priv-table-group > [class*='subtitle'] {
  margin-top: -8px;
}

.card-container-priv-table-group > div {
  display: none;
}

.card-container-priv-table-group > p {
  word-break: break-word;
}

.card-container-priv-table-group-toolbar {
  right: 34px;
  position: relative;
}

.card-container-priv-table-group > h1[class*='title'] {
  width: 100% !important;
}
