.modal-wrapper {
    background-color: #f7f7f7;
}

.modal-overlay-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0;
    background-color: #f7f7f7;
}

.modal-small,
.modal-small h2 {
    font-size: 12px !important;
}

.modal-message-wrapper {
    display: flex;
    justify-content: center !important;
    text-align: center !important;
    padding: 5px 0px 15px 0px !important;
    min-width: 500px !important;
}

.modal-message-wrapper [class*="modalInner"] {
    display: block !important;
}

.modal-button-wrapper {
    display: flex;
    justify-content: space-evenly;
    margin-top: 24px;
}

.modal-button-wrapper button {
    min-width: 110px !important;
}

.modal-message-error-subtitle {
    color: var(--er-error);
    padding: 12px 0px;
    font-size: 14px;
}

.modal-message-warning-subtitle {
    color: var(-er-warn);
    padding: 12px 0px;
    font-size: 14px;
}

.modal-message-subtitle {
    padding: 12px 0px;
    font-size: 14px;
    font-family: 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;
}

.modal-message-wrapper [class*="formGeneralSection"] {
    text-align: left;
}