.list-helper-modal {
    max-height: 700px !important;
    padding: 35px 15px 15px 5px !important;
}

.list-helper-modal-form-section {
    border-radius: 1em !important;
    padding: 1px 24px 24px 24px !important;
    margin: 11px 11px !important;
    max-height: 500px !important;
}

.list-helper-modal-form-section>div {
    margin-bottom: 10px !important;
}

.list-helper-modal-form-section>div.ReactTable.table>div.rt-table>div.rt-tbody>div>div>div:hover {
    background: var(--er-teal);
    color: white;
}

.list-helper-modal-form-section>div.ReactTable.table>div.rt-table>div.rt-tbody {
    overflow-y: auto !important;
    height: 216px !important;
}