/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-result {
    padding: 22px 32px;
    max-height: 40vh;
    overflow-y: scroll;
  }
  .ant-result-success .ant-result-icon > .anticon {
    color: var(--er-success);
  }
  .ant-result-error .ant-result-icon > .anticon {
    color: var(--er-error);
  }
  .ant-result-info .ant-result-icon > .anticon {
    color: var(--er-blue);;
  }
  .ant-result-warning .ant-result-icon > .anticon {
    color: var(--er-warn);
  }
  .ant-result-image {
    width: 250px;
    height: 295px;
    margin: auto;
  }
  .ant-result-icon {
    margin-bottom: 24px;
    text-align: center;
  }
  .ant-result-icon > .anticon {
    font-size: 72px;
  }
  .ant-result-title {
    color: rgba(0, 0, 0, 0.85);
    font-size: 24px;
    line-height: 1.8;
    text-align: center;
  }
  .ant-result-subtitle {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 1.6;
    text-align: center;
  }
  .ant-result-extra {
    margin-top: 32px;
    text-align: left;
  }
  .ant-result-extra > * {
    margin-right: 8px;
  }
  .ant-result-extra > *:last-child {
    margin-right: 0;
  }
  .ant-result-content {
    margin-top: 24px;
    padding: 24px 40px;
    background-color: #fafafa;
  }
