.select-excelsheet > div {
  width: 362px !important;
}

.fileInput___3dCJV .textSection___noJcg input[type='text'] {
  width: 300px !important;
}

.source-selector-medium-size-component {
  margin-top: 15px;
  display: block;
}
