.form-section {
  margin: 2px 9px !important;
  border-top: 3px solid var(--er-accent) !important;
}

.form-container {
  margin: 5px 0 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.redux-field-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 12px !important;
}

.redux-field-input {
  display: inline-flex;
  align-items: flex-end;
}

.form-section-wrapper {
  overflow-y: scroll;
  height: calc(100vh - 118px);
}

.datepicker-helper-text-input {
  width: 216px;
}

.redux-field-input [type='number'] {
  padding-right: 13px;
  text-align: left !important;
}

.redux-field-input input::-webkit-outer-spin-button,
.redux-field-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.redux-field-input input[type='number'] {
  -moz-appearance: textfield;
  padding: 0 15px 0 8px;
}

.editable-form-helper-button {
  position: absolute;
  right: -10px;
  bottom: 0px;
  height: 39px;
  width: 37px;
  outline: none;
  text-align: center;
  font-weight: bold;
  background-color: var(--er-grey-3);
  border-bottom-right-radius: 1.5em;
  border-top-right-radius: 1.5em;
  border: 0px;
}

.editable-form-helper-button:hover {
  background: var(--er-teal);
  color: white;
  cursor: pointer;
}

.form-container
  > div:nth-child(11)
  > div.bootstrap-col-md-8.redux-field-input
  > label
  > input {
  padding: 0px !important;
  margin-bottom: 0px !important;
}

.Select__indicator.Select__dropdown-indicator {
  position: relative !important;
  right: 12px !important;
  cursor: pointer !important;
}

.Select__value-container {
  cursor: pointer !important;
}

.Select__indicator.Select__clear-indicator {
  cursor: pointer !important;
  padding-right: 15px !important;
}

.Select__control {
  height: 39px !important;
}

.label-wrapper {
  display: flex !important;
  justify-content: space-between !important;
}

.label-text {
  font-size: 12px !important;
  text-align: left !important;
  color: #6d6d6d !important;
  margin-left: 11px !important;
  padding-bottom: 3px !important;
  min-width: 100px !important;
}

div.bootstrap-col-md-8.redux-field-input > label > input {
  margin-bottom: 0px !important;
  height: 38px;
}

.error-input {
  border: 1px solid red;
}

.form-section-wrapper
  > div
  > div.form-container
  > div
  > div.bootstrap-col-md-8.redux-field-input
  > label
  > div {
  margin-bottom: 0px !important;
}
