.target-medium-size-component {
  margin-top: 15px;
  display: block;
}
.target-medium-size-component input {
  box-sizing: border-box;
}

.target-checkboxes-div {
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
}

.target-popover-div {
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
  padding: '20px';
}

.target-avatar {
  background-color: var(--er-blue) !important;
  vertical-align: middle !important;
  margin-left: 14px !important;
}

.target-label-select-wrapper {
  margin-top: 10px !important;
  display: block;
}
