.divider-line {
    stroke: var(--er-accent);
    /* stroke-width: 8 */
}

.divider-line.small {
    stroke-width: 4
}

.divider-line.medium {
    stroke-width: 6
}

.divider-line.large {
    stroke-width: 8
}

.divider-line-decorator {
    border-radius: 1em;
}

.divider-line-decorator.fit {
    border-radius: 1em;
    top: 102px;
    position: absolute;
    left: 0px;
}