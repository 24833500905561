.icon {
  display: inline-block;
  font-size: 16px;
}

.epic-icon {
  line-height: 1;
  font-size: 16px !important;
}

.epic-icon:before {
  font-style: normal;
  font-weight: normal !important;
  vertical-align: top;
}
