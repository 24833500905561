.card-row-info {
  display: flex !important;
  left: -15px !important;
  position: relative !important;
  font-size: 12px !important;
  padding-top: 10px !important;
}

.card-row-info .label {
  color: var(--er-accent) !important;
  display: flex;
}

.card-row-info .text {
  word-break: break-all !important;
  padding-right: 0px !important;
}

.card-help-wrapper {
  display: flex;
  align-items: center;
}

.card-help-avatar {
  background-color: var(--er-blue) !important;
  vertical-align: middle !important;
  width: 14px !important;
  height: 14px !important;
  line-height: 14px !important;
  margin-left: 12px !important;
}
