.Select {
    width: 100%;
    font: 400 15px/1.2 'Helvetica', sans-serif;
    color: var(--er-black);
  }
  .Select .Select__control {
    border: none;
    border-radius: 17px;
    transition: none;
  }
  .Select .Select__control .Select__value-container {
    padding-left: 17px;
  }
  .Select .Select__control .Select__value-container .Select__single-value{
    margin-left: 3px;
    color:#6d6d6d
  }
  .Select .Select__control .Select__value-container .Select__placeholder {
    margin-left: 3px;
    color: var(--er-grey-4);
  }
  .Select .Select__control .Select__value-container .Select__value-container--has-value .Select__single-value,
  .Select .Select__control .Select__value-container .Select__value-container--has-value .Select__placeholder {
    color: var(--er-grey-7);
  }
  .Select .Select__control .Select__indicators .Select__indicator-separator {
    display: none;
  }
  .Select .Select__control .Select__control--is-focused {
    border: none;
    box-shadow: 0 0 0 1px rgba(0,0,0,0.1), 0 4px 11px rgba(0,0,0,0.1);
  }
  .Select .Select__control .Select__control--menu-is-open {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .Select .Select__menu {
    margin-top: 0;
    box-shadow: 0 0 0 1px rgba(0,0,0,0.1), 0 4px 11px rgba(0,0,0,0.1);
    border-radius: 0 0 17px 17px;
    overflow: hidden;
  }
  .Select .Select__menu .Select__menu-list {
    padding: 0;
  }
  .Select .Select__menu .Select__menu-list .Select__option {
    padding-left: 17px;
  }
  .Select .Select__menu .Select__menu-list .Select__option.Select__option--is-focused {
    background-color: var(--er-grey-1);
  }
  .Select .Select__menu .Select__menu-list .Select__option.Select__option--is-selected {
    background-color: var(--er-grey-2);
    color: var(--er-black);
  }
  .Select .Select__menu .Select__menu-list .Select__menu-notice--no-options {
    padding-left: 17px;
    text-align: left;
  }
