.container-layout-wrapper {
  overflow-y: hidden;
  overflow-x: hidden;
  height: calc(100vh - 60px);
}

.container-layout-body {
  margin: 0px 12px !important;
  padding: 0 !important;
  border-top: 3px solid var(--er-accent) !important;
  border-radius: 1rem;
}

.container-layout-body.fit [class*='header_'] {
  display: none;
}

.container-line {
  stroke: var(--er-accent);
  stroke-width: 8;
}

.container-line-decorator {
  border-radius: 1em;
}

.container-layout-body.props
  > div.ReactTable.table
  > div.rt-table
  > div.rt-tbody {
  overflow-y: auto;
  height: calc(100vh - 410px);
}

.container-layout-body [class*='header'] {
  margin-bottom: 0px !important;
}
