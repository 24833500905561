.banner {
  background-color: #000000;
  color: #ffffff;
  padding: 10px;
  text-align: center;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  margin: 10px 0;
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  overflow: hidden;
}

.banner .sub-message {
  font-size: 18px;
  margin-top: 10px;
}

.left-align {
  text-align: left;
  padding-left: 1px; /* Adjust this value to move it more to the left */
  color: #ffffff; /* Ensure the text color is white */
}

.bullet-list {
  list-style-type: disc;
  padding-left: 20px; /* Adjust this value to align the bullet points */
}

.bullet-list li {
  margin-bottom: 10px; /* Add some space between the bullet points */
  text-align: left;
}
