/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-notification {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    position: fixed;
    z-index: 1010;
    width: 384px;
    max-width: calc(100vw - 32px);
    margin-right: 24px;
  }
  .ant-notification-topLeft,
  .ant-notification-bottomLeft {
    margin-right: 0;
    margin-left: 24px;
  }
  .ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
  .ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
  .ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
  .ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active {
    -webkit-animation-name: NotificationLeftFadeIn;
            animation-name: NotificationLeftFadeIn;
  }
  .ant-notification-close-icon {
    font-size: 14px;
    cursor: pointer;
  }
  .ant-notification-notice {
    position: relative;
    margin-bottom: 16px;
    padding: 16px 24px;
    overflow: hidden;
    line-height: 1.5;
    background: #fff;
    border-radius: 4px;
    -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  .ant-notification-notice-message {
    display: inline-block;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    line-height: 24px;
  }
  .ant-notification-notice-message-single-line-auto-margin {
    display: block;
    width: calc(384px - 24px * 2 - 24px - 48px - 100%);
    max-width: 4px;
    background-color: transparent;
    pointer-events: none;
  }
  .ant-notification-notice-message-single-line-auto-margin::before {
    display: block;
    content: '';
  }
  .ant-notification-notice-description {
    font-size: 14px;
  }
  .ant-notification-notice-closable .ant-notification-notice-message {
    padding-right: 24px;
  }
  .ant-notification-notice-with-icon .ant-notification-notice-message {
    margin-bottom: 4px;
    margin-left: 48px;
    font-size: 16px;
  }
  .ant-notification-notice-with-icon .ant-notification-notice-description {
    margin-left: 48px;
    font-size: 14px;
  }
  .ant-notification-notice-icon {
    position: absolute;
    margin-left: 4px;
    font-size: 24px;
    line-height: 24px;
  }
  .anticon.ant-notification-notice-icon-success {
    color: var(--er-success);
  }
  .anticon.ant-notification-notice-icon-info {
    color: var(--er-blue);
  }
  .anticon.ant-notification-notice-icon-warning {
    color:  var(--er-warn);
  }
  .anticon.ant-notification-notice-icon-error {
    color: var(--er-error);
  }
  .ant-notification-notice-close {
    position: absolute;
    top: 16px;
    right: 22px;
    color: rgba(0, 0, 0, 0.45);
    outline: none;
  }
  .ant-notification-notice-close:hover {
    color: rgba(0, 0, 0, 0.67);
  }
  .ant-notification-notice-btn {
    float: right;
    margin-top: 16px;
  }
  .ant-notification .notification-fade-effect {
    -webkit-animation-duration: 0.24s;
            animation-duration: 0.24s;
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
            animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
  }
  .ant-notification-fade-enter,
  .ant-notification-fade-appear {
    opacity: 0;
    -webkit-animation-duration: 0.24s;
            animation-duration: 0.24s;
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
            animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ant-notification-fade-leave {
    -webkit-animation-duration: 0.24s;
            animation-duration: 0.24s;
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
            animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ant-notification-fade-enter.ant-notification-fade-enter-active,
  .ant-notification-fade-appear.ant-notification-fade-appear-active {
    -webkit-animation-name: NotificationFadeIn;
            animation-name: NotificationFadeIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ant-notification-fade-leave.ant-notification-fade-leave-active {
    -webkit-animation-name: NotificationFadeOut;
            animation-name: NotificationFadeOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  @-webkit-keyframes NotificationFadeIn {
    0% {
      left: 384px;
      opacity: 0;
    }
    100% {
      left: 0;
      opacity: 1;
    }
  }
  @keyframes NotificationFadeIn {
    0% {
      left: 384px;
      opacity: 0;
    }
    100% {
      left: 0;
      opacity: 1;
    }
  }
  @-webkit-keyframes NotificationLeftFadeIn {
    0% {
      right: 384px;
      opacity: 0;
    }
    100% {
      right: 0;
      opacity: 1;
    }
  }
  @keyframes NotificationLeftFadeIn {
    0% {
      right: 384px;
      opacity: 0;
    }
    100% {
      right: 0;
      opacity: 1;
    }
  }
  @-webkit-keyframes NotificationFadeOut {
    0% {
      max-height: 150px;
      margin-bottom: 16px;
      padding-top: 16px 24px;
      padding-bottom: 16px 24px;
      opacity: 1;
    }
    100% {
      max-height: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      opacity: 0;
    }
  }
  @keyframes NotificationFadeOut {
    0% {
      max-height: 150px;
      margin-bottom: 16px;
      padding-top: 16px 24px;
      padding-bottom: 16px 24px;
      opacity: 1;
    }
    100% {
      max-height: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      opacity: 0;
    }
  }
