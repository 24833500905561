.privileges {
    margin: 40px auto 0;
    width: 100%;
    padding: 10px 0;
}

.privileges > * {
    padding: 0;
}

.prohibited,
.allowed {
    width: 100%;
    height: 100%;
}

.prohibited {
    background: #ffebe5;
}

.allowed {
    background: #e3fcef;
}

.cell {
    background: #fff;
    border: none;
    border-left: 1px solid #c1c7d0 !important;
}

.cell:last-of-type {
    border-right: 1px solid #c1c7d0 !important;
}

.cell-padding {
    padding: 5px 10px !important;
}

.privileges .rt-tr-group {
    min-height: 55px;
    border-bottom: 1px solid #c1c7d0 !important;
}

.privileges .rt-tr-group:nth-of-type(2),
.privileges .rt-tr-group:nth-of-type(3),
.privileges .rt-tr-group:nth-of-type(5),
.privileges .rt-tr-group:nth-of-type(6),
.privileges .rt-tr-group:nth-of-type(8),
.privileges .rt-tr-group:nth-of-type(9),
.privileges .rt-tr-group:nth-of-type(11) {
    border-bottom: 2px solid #c1c7d0 !important;
}


