.aside-left-toolbar-title,
.aside-left-toolbar-title-link {
    top: 5px;
    position: relative;
    font-weight: bold;
    font-size: 18px;
    color: var(--er-accent)
}

.aside-left-toolbar-wrapper {
    display: flex;
    margin-right: -15px;
    margin-left: -15px;
    width: 100%;
}

.aside-left-toolbar-wrapper>a {
    text-decoration: none !important;
}

.aside-left-toolbar-title-link:hover {
    color: var(--er-teal)
}