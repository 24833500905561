.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px 20px;
  align-content: flex-start;
}

.card-container-custom-title-row {
  display: flex !important;
  align-items: center !important;
}

.card-container-custom-title-icon-col-last {
  position: relative;
  left: 1px;
  font-size: 18px;
  word-break: break-word;
  color: var(--er-teal);
}

.card-container-custom-title-icon-img-sm {
  width: 24px;
  height: 24px;
  padding-right: 8px;
}

.card-container-custom-title-icon-img-md {
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
}

.card-container-table {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  max-height: calc(100vh - 236px);
  overflow-y: auto;
  overflow-x: hidden !important;
}

.card-container-table.small {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  padding: 0px 10px;
  padding-bottom: 30px;
  max-height: calc(100vh - 306px);
  overflow-y: auto;
  overflow-x: hidden !important;
}

.card-container-table.medium {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  padding: 0px 10px;
  max-height: calc(100vh - 208px);
  overflow-y: auto;
  overflow-x: hidden !important;
}

.card-container-table > a,
.card-container-table-group > a {
  text-decoration: none !important;
  color: black !important;
}

.card-table-group > div {
  display: none;
}

.card-table-group > p {
  word-break: break-word !important;
  height: 90px;
}

.card-locked {
  background-color: var(--er-grey-3) !important;
}

.card-container-padlock {
  position: relative !important;
  display: flex !important;
  margin-right: -15px !important;
  margin-left: -15px !important;
  width: 100% !important;
}

.card-container-padlock-text {
  margin-top: 5px !important;
  margin-left: -16px !important;
}
