.semaphore-wrapper {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.semaphore-light {
  border-radius: 50%;
  width: 14px;
  height: 14px;
}

.semaphore-text {
  margin-left: 8px;
}
