/* @import '~antd/dist/antd.css'; */
.formGeneralSection {
  margin: 0 0 32px 0 !important;
}

.formPreviewSection {
  margin: 1% 0 !important;
  padding: 30px 2% !important;
}

.upload-wizard {
  margin: 32px 2%;
}

.div-buttons-wrapper {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.customClassName {
  max-width: 180px;
  z-index: 900;
}
.customClassName .ActiveSelect__control {
  background: #5197d6;
  height: 27px;
  max-height: 27px;
  padding: 0;
}
.customClassName
  .ActiveSelect__control
  .ActiveSelect__value-container
  .ActiveSelect__single-value {
  color: white !important;
}
.customClassName
  .ActiveSelect__control
  .ActiveSelect__value-container
  .ActiveSelect__placeholder {
  color: white;
}
.customClassName
  .ActiveSelect__control
  .ActiveSelect__indicators
  .ActiveSelect__dropdown-indicator {
  color: white;
}

.upload-wizard [class*='radioGroup'] {
  width: 80% !important;
}
