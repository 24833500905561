.ReactTable .rt-table .-filters .rt-tr .rt-th:first-child,
.ReactTable .rt-table .-filters .rt-tr .rt-th:last-child {
    border-top-left-radius: initial !important;
    border-top-right-radius: initial !important;
}

.ReactTable .rt-tbody {
    overflow: hidden;
}

.divHeaderTableLabel>img {
    opacity: 0.5 !important
}

.rt-noData {
    font: 500 12px/22px 'Helvetica Neue Regular';
    color: #000000 !important;
    visibility: hidden;
}

.ReactTable {
	border: none !important
}

.table .rt-noData {
	position: relative;
	transform: unset;
	text-align: center;
	left: 0;
	top: 0;
}

.ReactTable .rt-table .rt-thead .rt-tr .rt-th {
	padding: 5px;
	font: 700 16px/24px 'Trade Gothic' !important;
	-webkit-font-smoothing: antialiased;
	text-transform: uppercase;
	border-top: 1px solid var(--er-grey-6);
	border-right: 1px solid var(--er-white);
	background: var(--er-grey-6);
	color: var(--er-white);
}

.ReactTable .rt-table .rt-thead .rt-tr .rt-th.-sort-asc,
.ReactTable .rt-table .rt-thead .rt-tr .rt-th.-sort-desc {
	box-shadow: none;
	background: var(--er-teal);
}

.ReactTable .rt-table .rt-thead .rt-tr .rt-th.-sort-asc:before,
.ReactTable .rt-table .rt-thead .rt-tr .rt-th.-sort-desc:before {
	content: '';
	position: absolute;
	top: calc(50% - 2px);
	right: 8px;
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid var(--er-white);
}

.ReactTable .rt-table .rt-thead .rt-tr .rt-th.-sort-desc:before {
	top: calc(50% - 3px);
	border-top: none;
	border-bottom: 5px solid var(--er-white);
}

.ReactTable .rt-table .rt-thead .rt-tr .rt-th:first-child {
	border-top-left-radius: 15px;
	border-left: 1px solid var(--er-grey-6);
}

.ReactTable .rt-table .rt-thead .rt-tr .rt-th:last-child {
	border-right: 1px solid var(--er-grey-6);
	border-top-right-radius: 15px;
}

.ReactTable .rt-table .rt-tbody .rt-tr-group {
	border: none;
}

.ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr {
	background: var(--er-white);
}

.ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-even {
	background: var(--er-grey-2);
}

.ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr .rt-td {
	padding: 5px;
	padding-left: 10px;
	font: 500 12px/22px 'Helvetica Neue Regular';
	border-right: 1px solid var(--er-grey-4);
}

.ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr .rt-td:first-child {
	border-left: 1px solid var(--er-grey-4);
}

.ReactTable .rt-table .rt-tbody .rt-tr-group:last-child .rt-td {
	border-bottom: 1px solid var(--er-grey-4);
}

.ReactTable.table.read-only > div.rt-table > div.rt-tbody [class*="rt-td"] {
	border: 1px solid #F5F5F5 !important;
	padding: 0 8px !important;
	box-sizing: border-box;
}

.ReactTable.table.read-only > div.rt-table > div.rt-tbody [class*="rt-tr -even"] {
	background:  lightgray !important;
}

.ReactTable.table.read-only > div.rt-table > div.rt-tbody [class*="rt-tr -odd"] {
	background: var(--er-grey-2);
}