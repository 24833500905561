.card-toolbar {
    align-content: end;
    display: contents;
}

.card-toolbar>a {
    color:black !important;
}

.card-toolbar-button {
	padding: 0px 10px 0px 10px !important;
	color: black !important;
}
