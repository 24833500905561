.property-help-icon-wrapper {
    top: 2px;
    position: relative;
}

.property-help-row-wrapper {
    display: flex;
    margin-right: -15px;
    margin-left: -15px;
    width: 100%;
    height: 27px !important;
}

.property-help-row-desc-wrapper {
    display: flex;
    margin-right: -15px;
    margin-left: -15px;
    width: 100%;
}

.property-help-popover-content-wrapper {
    max-width: 420px !important;
    min-width: 270px !important;
    padding-bottom: 20px;
}

.property-help-accent {
    font-weight: bold;
    color: var(--er-accent);
}

.property-help-p-descp {
    color: var(--er-blue);
}

.property-help-col-p {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 91%;
}